.sectionNote {
  display: grid;
  width: 100%;
  height: 80vh;
  margin: 0 auto 10rem auto;
  background-image: url(../Components/Assets/LandingPageBcg/BH6.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.divNote {
  width: 40%;
  /* height: 80%; */
  margin: auto 0 auto 5rem;
  background-color: #fff;
  padding: 2rem;
}

.noteHeading {
  text-align: start;
  margin-bottom: 1.1rem;
  font-family: "Poppins", serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.noteParagraph {
  text-align: start;
  font-family: "Poppins", cursive;
  letter-spacing: 1px;
  font-size: 0.9rem;
  line-height: 1.6;
}

@media screen and (min-width: 1500px) {
  .divNote {
    width: 35%;
  }

  .noteHeading {
    font-size: 1.5rem;
  }

  .noteParagraph {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1000px) {
  .divNote {
    width: 50%;
  }

  .noteHeading {
    font-size: 1.7vw;
  }

  .noteParagraph {
    font-size: 1.4vw;
  }
}

@media screen and (max-width: 500px) {
  .sectionNote {
    height: 60vh;
  }

  .divNote {
    width: 80%;
    margin: auto;
  }

  .noteHeading {
    font-size: 0.7rem;
  }

  .noteParagraph {
    font-size: 0.6rem;
  }
}
