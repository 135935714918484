@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Pacifico&family=Playfair+Display:wght@500&family=Poppins:wght@100;400&display=swap");



.sectionMvpm {
  max-width: 85%;
  margin: 6rem auto;
  min-height: 30rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.divMvpm {
  height: 100%;
  width: 100%;
  background-color: #fbf0f1;
  padding: 2rem 1rem 1rem 1rem;
}

.divMvpm:hover {
  transition: transform 1s ease-in-out;
  transform: scale(1.05);
}

.divMvpm-1 {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #f0fbfa;
  padding: 2rem 1rem 1rem 1rem;
  transform: scale(1.05);
}

.divMvpm-1:hover {
  transition: transform 1s ease-in-out;
  transform: scale(1.1);
}

.mvpmHeading {
  font-size: 1.1rem;
  font-family: "Poppins", cursive;
  padding-bottom: 1rem;
  letter-spacing: 2px;
}

.mvpmText {
  text-align: start;
  font-size: 0.9rem;
}

.mvpmTextList {
  list-style: none;
}

.mvpmTextList li {
  gap: 5px;
}

.mvpmText1 {
  font-size: 2rem;
  text-align: start;
}

.mvpmSpan {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.fa-circle {
  font-size: 0.4rem;
}

.mvpmSpan i {
  margin-top: 0.5rem;
}

@media screen and (min-width: 1500px) {
  .divMvpm {
    padding: 2rem 3rem 1rem 3rem;
  }

  .divMvpm-1 {
    padding: 2rem 2rem 1rem 2rem;
  }

  .sectionMvpm {
    min-height: 40em;
  }

  .mvpmHeading {
    font-size: 1.5rem;
    padding-bottom: 1rem;
    letter-spacing: 2px;
  }

  .mvpmText {
    text-align: start;
    font-size: 1.2rem;
    line-height: 30px;
  }
}

@media screen and (max-width: 850px) {
  .sectionMvpm {
    max-width: 85%;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: none;
  }

  .mvpmHeading {
    font-size: 0.7rem;
  }

  .mvpmText {
    text-align: start;
    font-size: 0.6rem;
  }

  .divMvpm {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }

  .divMvpm-1 {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}
