.sectionTeamMembers {
  width: 90%;
  margin: 10rem auto 0 auto;
  text-align: start;
}

.divTeamMembersHeading {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1rem;
}

.teamMembers {
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.teamMembers-1 {
  display: grid;
  width: 20%;
}

.teamMembersName h1 {
  font-size: 0.9rem;
}

.teamMembersName p {
  font-size: 0.9rem;
  color: #180e7f;
  margin-bottom: 1rem;
}

.divTeamMembersImage {
  height: 7rem;
  width: 7rem;
  margin: auto;
}

.teamMembersImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.teamMembersName {
  text-align: center;
  font-size: 0.9rem;
}

.teamMembersText {
  width: 50%;
}

@media screen and (max-width: 800px) {
  .sectionTeamMembers {
    width: 90%;
    margin: 5rem auto 5rem auto;
    text-align: start;
  }

  .divTeamMembersHeading {
    font-size: 0.8rem;
    color: #180e7f;
    margin-bottom: 0.5rem;
  }

  .divTeamMembersImage {
    height: 4rem;
    width: 4rem;
    /* margin: auto; */
  }

  .teamMembersImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .teamMembers-1 h1 {
    font-size: 0.6rem;
    text-align: center;
  }

  .teamMembersName p {
    font-size: 0.5rem;
    color: #180e7f;
    margin-bottom: 0;
    text-align: center;
  }
  /* 
  .teamMembers {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
  } */

  .teamMembersName {
    text-align: start;
  }

  .teamMembersText {
    width: 90%;
    font-size: 0.6rem;
  }
}
