.sectionPhrase {
  height: 100vh;
  width: 100%;
  display: grid;
}

.divPhrase {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.divPhrase span {
  font-size: 4rem;
  color: #ff2400;
}

.textPhrase-1 {
  font-size: 1.1rem;
  letter-spacing: 4px;
}

.textPhrase-2 {
  font-size: 1rem;
  letter-spacing: 3px;
}

.textPhrase-3 {
  font-size: 0.9rem;
  letter-spacing: 2px;
}

.textPhrase-4 {
  font-size: 1.1rem;
  letter-spacing: 5px;
  color: #ff2400;
  font-weight: 600;
}

@media screen and (min-width: 1500px) {
  .textPhrase-1 {
    font-size: 1.3rem;
  }

  .textPhrase-2 {
    font-size: 1.2rem;
  }

  .textPhrase-3 {
    font-size: 1.1rem;
  }

  .textPhrase-4 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1000px) {
  .sectionPhrase {
    height: 70vh;
    width: 85vw;
    margin: auto;
  }

  .divPhrase {
    gap: 0.5rem;
  }

  .divPhrase span {
    font-size: 1.5rem;
  }

  .textPhrase-1 {
    font-size: 0.9rem;
  }

  .textPhrase-2 {
    font-size: 0.8rem;
  }

  .textPhrase-3 {
    font-size: 0.7rem;
  }

  .textPhrase-4 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .sectionPhrase {
    height: 50vh;

  }

  .textPhrase-1 {
    font-size: 0.8rem;
  }

  .textPhrase-2 {
    font-size: 0.7rem;
  }

  .textPhrase-3 {
    font-size: 0.65rem;
  }

  .textPhrase-4 {
    font-size: 0.8rem;
  }
}
