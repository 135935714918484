@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&family=Poppins:wght@100;400&family=Roboto+Serif:opsz,wght@8..144,700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 4rem;
  border-radius: 15px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
  background: transparent;
  backdrop-filter: blur(5px);
}

.navbarLogoImage {
  width: 7rem;
  height: 6rem;
  margin-top: 0.5rem;
  object-fit: contain;
  cursor: pointer;
}

.navMenu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.navLinks {
  text-decoration: none;
  color: #180e7f;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.7rem 1.5rem;
  white-space: nowrap;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.navLinksContact {
  text-decoration: none;
  color: #180e7f;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.7rem 1.5rem;
  white-space: nowrap;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ff2400;
  border-radius: 30px;
}

.navLinksList::after {
  content: "";
  width: 0%;
  height: 4px;
  background: #ff2400;
  display: block;
  margin: auto;
  transition: 0.5s;
}

.navLinksList:hover::after {
  width: 85%;
}

.navbarButton {
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.navbarButton:hover {
  background-color: blue;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.menuIcons {
  position: absolute;
  right: 10%;
  display: none;
}

.divHero {
  max-width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url(../Components/Assets/LandingPageBcg/BCG1.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: change 30s infinite ease-in-out;
  object-fit: contain;
  text-align: start;
}

@keyframes change {
  0% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG1.webp);
    object-fit: fill;
    filter: brightness(100%);
  }

  20% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG1.webp);
    object-fit: fill;
  }

  40% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG2.webp);
    object-fit: fill;
  }

  60% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG7.webp);
    object-fit: fill;
  }

  80% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG3.webp);
    object-fit: fill;
  }

  100% {
    background-image: url(../Components/Assets/LandingPageBcg/BCG3.webp);
    object-fit: fill;
  }
}

.landingPageText {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  filter: brightness(100%);
}

.openingPhrase {
  font-size: 4.5vw;
  color: #ff2400;
  padding-bottom: 1.5rem;
  width: 100%;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  text-align: start;
  letter-spacing: 10px;
}

.openingPhraseItems {
  font-size: 1.5vw;
  color: #ffffff;
  padding-bottom: 1.5rem;
  text-transform: capitalize;
}

.GITButton {
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
  color: #ffffff;
  background-color: #ff2400;
  padding: 5px 20px 5px 20px;
  border: none;
}

.textGITButton {
  text-decoration: none;
}

.GITButton:hover {
  scale: 1.05;
  transition: 2s ease-out;
  background-color: #180e7f;
}

.linkArrow {
  color: #fff;
  font-size: 1.5rem;
  margin: auto;
  vertical-align: middle;
  margin-bottom: 2px;
}

@media screen and (min-width: 1500px) {
  .navLinks {
    font-size: 1.1rem;
    padding: 0.7rem 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .landingPageText {
    padding-left: 1rem;
  }

  .navLinksContact {
    font-size: 0.6rem;
    padding: 0.5rem 1.2rem;
    border-radius: 30px;
  }

  .openingPhrase {
    font-size: 4vw;
  }

  .openingPhraseItems {
    font-size: 1.5vw;
  }

  .GITButton {
    font-size: 0.6rem;
    padding: 1px 6px 1px 6px;
    margin-right: 1rem;
  }

  .textGITButton {
    font-size: 0.6rem;
  }

  .linkArrow {
    font-size: 0.5rem;
    margin-bottom: 2px;
  }

  .navbarItems {
    z-index: 9999;
  }

  .navbarItems {
    width: 100%;
    height: 3.5rem;
    border-radius: 13px 13px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    margin-top: 0.5rem;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: -100vh;
    left: 0;
    opacity: 0;
    align-items: stretch;
    /* padding: 80px 0 30px 0; */
    margin: auto;
    text-align: center;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .navLinksList:hover::after {
    width: 30%;
  }

  .navMenu.active {
    top: 0;
    opacity: 1;
    z-index: 1;
    transition: 1s ease-in-out;
  }

  .navLinks {
    display: block;
    width: 100%;
    font-size: 0.8rem;
    padding: 2rem 0;
    color: #fff;
  }

  .navbarButton {
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .menuIcons {
    display: block;
    z-index: 9999;
  }

  .menuIcons i {
    font-size: 1.7rem;
    color: #2c1ae5;
    cursor: pointer;
  }

  .navbarLogoImage {
    width: 7rem;
    height: 100%;
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .navLinks {
    font-size: 0.6rem;
    padding: 1rem 0;
  }

  .navLinksContact {
    font-size: 0.6rem;
    padding: 0.4rem 1.3rem;
  }

  .openingPhrase {
    font-size: 1.7rem;
    letter-spacing: 6px;
  }

  .openingPhraseItems {
    font-size: 0.6rem;
  }

  .GITButton {
    font-size: 0.6rem;
    padding: 1px 6px 1px 6px;
    margin-right: 1rem;
  }

  .textGITButton {
    font-size: 0.6rem;
  }

  .linkArrow {
    font-size: 0.5rem;
    margin-bottom: 2px;
    display: none;
  }
}
